
import { computed, defineComponent, onMounted, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UserService from "@/core/services/UserService";
import InvoiceService from "@/core/services/car/InvoiceService";
import { boolean } from "yup/lib/locale";
import Multiselect from "@vueform/multiselect";
import CustomerService from "@/core/services/car/CustomerService";
import moment from "moment";
import { ElNotification } from "element-plus";
import MasterService from "@/core/services/car/MasterService";
import IMultiselect from "@/models/IMultiselect";
import ManualOrderService from "@/core/services/car/ManualOrderService";
import ViewLead from "@/views/car/manual-orders/ViewLead.vue";
import { hideModal } from "@/core/helpers/dom";
import { Mutations } from "@/store/enums/StoreEnums";
import ManualOrderModal from "./ManualOrderModal.vue";
import router from "@/router";
import { notif } from "@/store/stateless/store";
import InvoiceModal from "../invoice/InvoiceModal.vue";
import LocalStorageServices from "@/core/services/LocalStorageServices";

interface AddTempMOParam {
  policy_id: string;
  customer_id: string;
  lead_id: string;
  is_agency_repair: string;
  original_price: string;
  sale_price: string;
  name: string;
  email: string;
  phone_number: string;
  parent_invoice_id?: any;
  invoice_id?: any;
  is_discount_given: string;
  discount_amount: number;
  policy_sales_agent_id: any;
  form_session_id:any;
}
interface InvoiceData {
  lead: string;
  lead_id: string;
  policy_id: string;
  customer_id: string;
  is_agency_repair: any;
  is_agency_repair_disabled?:any;
  original_price: number;
  sale_price: number;
  name: string;
  email: string;
  phone_number: string;
  is_discount_given: string;
  discount_amount: number;
  invoice_id?: any;
  parent_invoice_id?: any;
  validation?: boolean;
  vehicle_type:number;
}
interface AddInvoiceBodyParam {
  policy_sales_agent_id: any;
  payment_type: any;
  order_description: string;
  language: string;
  expiry_date_time: string;
  invoice_doc: string;
  reference_no: any;
}
export default defineComponent({
  name: "add-manual-order",
  props: {},
  components: { Multiselect, ViewLead, ManualOrderModal, InvoiceModal },
  data() {
    return {
      dob: "",
      policyBegin: "",
      registrationDate: ""
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 2021 - index
      );
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const inst = getCurrentInstance();
    
    const onSelectCustomerRef = ref();
    const isView = route.name == "manual-order-show" ? true : false;
    // console.log("isView", isView);
    let invoiceData = ref<Array<InvoiceData>>([]);
    // let searchList = ref<any>();
    let showLoader = ref<boolean>(false);
    let keyword = ref<string>("");
    let customerInfo = ref<Array<any>>([]);
    let invoiceInfo = ref<Array<any>>([]);
    // let totalAmount = ref<number>(0);
    let selectedCustomer = [];
    let editInvoiceObj = ref<Array<any>>([]);

    let isShowValidate = ref<boolean>(false);
    let isShowLeadValidate = ref<boolean>(false);
    let parentInvoiceId = ref<number>(0);
    const searchCustomerModalRef = ref<null | HTMLElement>(null);
    let paymentDate = ref<string>("");

    let customerEmail = ref<string>("");
    let addTempMOParam = ref<AddTempMOParam>({
      policy_id: "-1",
      customer_id: "",
      lead_id: "",
      is_agency_repair: "",
      original_price: "",
      sale_price: "",
      name: "",
      email: "",
      phone_number: "",
      is_discount_given: "0",
      discount_amount: 0,
      policy_sales_agent_id: null,
      form_session_id: null
    });

    let addInvoiceBodyParam = ref<AddInvoiceBodyParam>({
      policy_sales_agent_id: null,
      payment_type: -1,
      order_description: "",
      language: "en",
      expiry_date_time: "",
      invoice_doc: "",
      reference_no: ""
    });
    let isFormValid = ref<boolean>(true);
    const rules = ref({
      email: {
        show: false,
        message: "Please enter Customer Email"
      },
      expiry_date: {
        show: false,
        message: "Expiry date is mandatory for online"
      },
      policy_agent: {
        show: false,
        message: "Please select Policy agent"
      },
      invoice_plan: {
        show: false,
        message: "Please select Invoice Plan"
      },
      agency_repair: {
        show: false,
        message: "Please select Agency Repair"
      },
      language: {
        show: false,
        message: "Please select language"
      },
      sale_price: {
        show: false,
        message: "Please enter Sales price"
      },
      order_description: {
        show: false,
        message: "Please enter Order Description"
      },
      payment_type: {
        show: false,
        message: "Please select Payment Type"
      },
      file_data: {
        show: false,
        message: "Please upload file for COD / Direct"
      },
      invoice_data: {
        show: false,
        message: "Please enter all the required fields marked "
      },
      customer_data: {
        show: false,
        message: "Please select customer"
      },
      lead_data: {
        show: false,
        message: "Please add lead"
      },
      lead: {
        show: false,
        message: "Please select lead"
      },
      insurance_plan: {
        show: false,
        message: "Please select insurance plan"
      },
      is_agency_repair: {
        show: false,
        message: "Please select is agency repair"
      },
      policy_price: {
        show: false,
        message: "Please enter policy price"
      },
      sale_prices: {
        show: false,
        message: "Please enter sale price"
      },
      discount_given: {
        show: false,
        message: "Please select discount given"
      },
      discount_amount: {
        show: false,
        message: "Please enter discount amount"
      },
      is_discount_given: {
        show: false,
        message: "Select Yes as there is a discount"
      },
      reference_no: {
        show: false,
        message: "Please enter Reference No."
      },
      payment_date: {
        show: false,
        message: "Please select payment date",
      },
    });
    let salesAgentList = ref({});
    let userStr: any;
    let userObj = ref<any>({});
    let formSessionId = ref<any>(null);

    onMounted(() => {
      // random session
      formSessionId.value = (Math.random() + 1).toString(36).substring(7);
      setTimeout(() => {
        const ref_agent_id:any = inst?.refs.ref_agent_id
          ref_agent_id.focus()
      }, 1000);
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Generate Manual Orders", ["Manual Orders"]);

      
      const payload = {
        is_policy_both:true
      }
      InvoiceService.getInsurancePolicyList(payload);

      if (
        route.params.invoiceId !== undefined &&
        route.params.invoiceId != null
      ) {
        const title = isView ? "View Manual Orders" : "Edit Manual Orders";
        setCurrentPageBreadcrumbs(title, ["Manual Orders"]);
        let params = {
          invoice_id: route.params.invoiceId,
          form_session_id:formSessionId.value
        };

        Promise.all([ManualOrderService.showManualOrder(params)]).then(
          (data) => {
            let editManualOrderObj = store.getters.editManualOrder;
            if (editManualOrderObj !== undefined) {
              if (isView) {
                editInvoiceObj.value = editManualOrderObj;
                addInvoiceBodyParam.value = editManualOrderObj;
              }

              addCustomerInvoiceData(editManualOrderObj);
              parentInvoiceId.value = editManualOrderObj.id;
              editManualOrderObj.manual_orders.forEach((order, index) => {
                addCustomerInvoiceData(order);
              });


              UserService.getDomSalesAgentsManagerTeamLeader().then((result) => {
                salesAgentList.value = result.data.data.agents;
                addInvoiceBodyParam.value.policy_sales_agent_id =
                editManualOrderObj.policy_sales_agent_id;
              });
              
            }
          }
        );
      } else {
        userStr = LocalStorageServices.getUser();
        userObj.value = JSON.parse(userStr);

        UserService.getDomSalesAgentsManagerTeamLeader().then((result) => {
          salesAgentList.value = result.data.data.agents;
          addInvoiceBodyParam.value.policy_sales_agent_id =
          userObj.value.role_id != 1 ? userObj.value.id : null;
        });
        
       
      }
    });

    const addCustomerInvoiceData = (manualOrder) => {
      let hasCustomer = customerInfo.value.filter(function (info) {
        return info.id == manualOrder.customer.id;
      }).length;

      if (hasCustomer == 0) {
        customerInfo.value.push(manualOrder.customer);
      }

      // addInvoiceInfo(manualOrder.customer, false);

      let input = {
        invoice_id: manualOrder.id,
        lead: manualOrder.car_lead ? manualOrder.car_lead.car_name_with_driver : "-",
        lead_id: manualOrder.car_lead ? manualOrder.car_lead.id : "-",
        policy_id: manualOrder.bai_car_insurance_policy_id,
        customer_id: manualOrder.customer_id,
        is_agency_repair: manualOrder.is_agency_repair,
        is_agency_repair_disabled:manualOrder.policy ? manualOrder.policy.policy_type == 5 ? true : false : false,
        original_price: manualOrder.original_price,
        sale_price: manualOrder.sale_price,
        name: manualOrder.name,
        email: manualOrder.email,
        phone_number: manualOrder.phone_number,
        parent_invoice_id: manualOrder.parent_invoice_id,
        validation: false,
        policy: manualOrder.policy ? manualOrder.policy : null,
        is_discount_given: manualOrder.is_discount_given,
        discount_amount: manualOrder.discount_amount,
        vehicle_type:manualOrder.car_lead?.vehicle_type,
      };
      invoiceInfo.value.push(input);
      invoiceData.value.push(input);

      onChangeSalePrice();
    };

    const getSearchResult = ($event) => {
      const keyword = $event.target.value;
      const params = {
        type: 1,
        keyword: keyword,
        select: 1
      };
      if (keyword.length > 2) {
        searchResult(params);
      } else {
        resetSearchResult();
      }
      // else
      // {
      //     searchList.value =  [];
      // }
    };

    const searchResult = (params) => {
      showLoader.value = true;
      Promise.all([MasterService.getSearchResultMo(params)]).then((data) => {
        showLoader.value = false;
      });
    };

    const resetSearchResult = () => {
      MasterService.resetSearchResult();
    };

    let getSearch = computed(() => {
      return store.getters.getSearchMo.result;
    });
    let searchList = ref<IMultiselect>({
      placeholder: "Enter User/Customer",
      value: null,
      options: getSearch,
      searchable: true,
      clearOnSelect: true
    });

    let expiryDate = ref<string>("");
    let expiryDateTime: any;
    expiryDateTime = "";
    const getExpiryDateTime = (dateTime) => {
      const dateformart = moment(
        dateTime.target.value,
        "DD/MM/YYYY",
        true
      ).isValid()
        ? moment(dateTime.target.value, "DD/MM/YYYY").format("YYYY-MM-DD")
        : dateTime.target.value;
      var dueDateTime = new Date(dateformart);
      expiryDateTime =
        dueDateTime.getFullYear() +
        "-" +
        (dueDateTime.getMonth() + 1) +
        "-" +
        dueDateTime.getDate();
    };

    let errMsg = ref<boolean>(false);

    const isClick = ref<boolean>(false);

    const loading = ref<boolean>(false);

    const openErrorMessage = (message) => {
      ElNotification({
        title: "Something went wrong!",
        dangerouslyUseHTMLString: true,
        message: message,
        type: "error"
      });
    };

    const openSuccessMessage = (message) => {
      ElNotification({
        customClass: "notification-success",
        title: message,
        dangerouslyUseHTMLString: true,
        message: "",
        type: "success"
      });
    };

    const isError = ref<boolean>(false);
    const addManualOrder = async() => {
      isError.value = false;
      isClick.value = true;
      errorMessages.value = [];
      isFormValid.value = true;

      addInvoiceBodyParam.value.expiry_date_time = expiryDate.value

      let formData = new FormData();

      if (expiryDateTime == "") {
        var dueDateTime = new Date();
        expiryDateTime =
          dueDateTime.getFullYear() +
          "-" +
          (dueDateTime.getMonth() + 1) +
          "-" +
          dueDateTime.getDate() +
          " " +
          moment(dueDateTime.toLocaleTimeString(), "hh:mm A").format("HH:mm");
      }
      formData.append(
        "policy_sales_agent_id",
        addInvoiceBodyParam.value.policy_sales_agent_id
      );
      formData.append("payment_type", addInvoiceBodyParam.value.payment_type);
      formData.append(
        "order_description",
        addInvoiceBodyParam.value.order_description
      );
      formData.append("language", addInvoiceBodyParam.value.language);
      formData.append("reference_no", addInvoiceBodyParam.value.reference_no);
      //For COD or Direct
      if(addInvoiceBodyParam.value.payment_type == '2' || addInvoiceBodyParam.value.payment_type == '3')
      {
        formData.append("payment_date", paymentDate.value);
      }
      formData.append("expiry_date_time", expiryDate.value);
      formData.append("invoice_doc", file);

      invoiceData.value.forEach((invoice, index) => {
        if (invoice.invoice_id !== undefined) {
          formData.append(
            "manual_order_data[" + index + "][invoice_id]",
            invoice.invoice_id
          );
        }

        formData.append(
          "manual_order_data[" + index + "][customer_id]",
          invoice.customer_id
        );
        formData.append(
          "manual_order_data[" + index + "][lead_id]",
          invoice.lead_id && Number.isInteger(invoice.lead_id) ? invoice.lead_id : invoice.lead
        );
        formData.append(
          "manual_order_data[" + index + "][is_agency_repair]",
          invoice.is_agency_repair
        );
        formData.append(
          "manual_order_data[" + index + "][original_price]",
          invoice.original_price.toString()
        );
        formData.append(
          "manual_order_data[" + index + "][sale_price]",
          invoice.sale_price.toString()
        );
        formData.append(
          "manual_order_data[" + index + "][policy_id]",
          invoice.policy_id
        );

        formData.append(
          "manual_order_data[" + index + "][is_discount_given]",
          invoice.is_discount_given
        );
        formData.append(
          "manual_order_data[" + index + "][discount_amount]",
          invoice.discount_amount.toString()
        );

        formData.append("manual_order_data[" + index + "][name]", invoice.name);
        formData.append(
          "manual_order_data[" + index + "][email]",
          invoice.email
        );
        formData.append(
          "manual_order_data[" + index + "][phone_number]",
          invoice.phone_number
        );
      });

      
      validateManualOrderForm();
      
      // let data = '<ul>';
      // let i = 0;
      // for(i; i < errorMessages.value.length; i++) {
      //   if(errorMessages.value[i]) {
      //     data += '<li style="color:red;">' + errorMessages.value[i] + '</li>';
      //   }
      // }
      // data += '</ul>';

      if (isFormValid.value) {
        const data = await ManualOrderService.addManualOrder(formData)
        if(data.status < 299) {
          isError.value = false;
            isClick.value = false;

            let fetchManualOrder = computed(() => {
              return store.getters.fetchManualOrder;
            });

            const invoice = fetchManualOrder.value;

            store.commit(Mutations.SET_INVOICE_MODAL);
            store.commit(Mutations.SET_INVOICE_MODAL_VALUE, invoice);
            notif.simple(
              "Manual Orders",
              "success",
              "You have successfully added Manual Orders!"
            );
        } else {
          if(data.data.data.reference_no) {
              rules.value.reference_no.show = true;
              rules.value.reference_no.message = 'Reference no. already exists'
          }
          isClick.value = false;
          isError.value = true;
        }
          // .then(() => {
          //   // openSuccessMessage("Manual order added successfully");
            
          // })
          // .catch(() => {
          //   isClick.value = false;
          //   isError.value = true;
          // });
      } else {
        isError.value = false;
        isClick.value = false;
        ScrollPage();
        focusField()
      }
    };
    const ScrollPage = () => {
      window.scrollTo(0, 0);
    };
    const errorMessages = ref<Array<string>>([]);
    const validateManualOrderForm = () => {
      if (addInvoiceBodyParam.value.payment_type == "1") {
        if (expiryDate.value == "") {
          rules.value.expiry_date.show = true;
          errorMessages.value.push("Please select Payment Type");
          isFormValid.value = false;
        }
        if (addInvoiceBodyParam.value.language == "-1") {
          rules.value.language.show = true;
          errorMessages.value.push("Please select language");
          isFormValid.value = false;
        }
      }

      if (
        addInvoiceBodyParam.value.payment_type == "2" ||
        addInvoiceBodyParam.value.payment_type == "3"
      ) {
        if (file == undefined) {
          rules.value.file_data.show = true;
          errorMessages.value.push("Please upload File for COD / Direct");
          isFormValid.value = false;
        }

        if (addInvoiceBodyParam.value.reference_no == "") {
          rules.value.reference_no.show = true;
          isFormValid.value = false;
        } else if (
          addInvoiceBodyParam.value.reference_no != "" &&
          addInvoiceBodyParam.value.reference_no.length > 30
        ) {
          rules.value.reference_no.show = true;
          rules.value.reference_no.message =
            "Reference No. should be less than 30 characters";
          isFormValid.value = false;
        } else {
          rules.value.reference_no.show = false;
        }

        if(paymentDate.value == ""){
          rules.value.payment_date.show = true;
          isFormValid.value = false;
        }
      }

      if (addInvoiceBodyParam.value.policy_sales_agent_id == null) {
        rules.value.policy_agent.show = true;
        isFormValid.value = false;
        errorMessages.value.push("Please select Policy agent");
        window.scrollTo(0, 0);
      }

      // editPolicyObj.value.car_lead_invoices.forEach((invoice) => {
      //   if (invoice.credit_amount == null || invoice.debit_amount == null) {
      //     rules.value.debit_note.show = true;
      //     isFormValid.value = false;
      //   }
      // });

      if (invoiceData.value.length > 0) {
        invoiceData.value.forEach((invoice) => {
          validateInvoiceData(invoice);
        });
      }
      if (invoiceData.value.length == 0) {
        rules.value.lead_data.show = true;
        isFormValid.value = false;
      }

      if (customerInfo.value.length == 0) {
        rules.value.customer_data.show = true;
        isFormValid.value = false;
      }

      if (addInvoiceBodyParam.value.payment_type == -1) {
        rules.value.payment_type.show = true;
        errorMessages.value.push("Please select Payment Type");
        isFormValid.value = false;
      }
      if (addInvoiceBodyParam.value.order_description == "") {
        rules.value.order_description.show = true;
        errorMessages.value.push("Please enter Order Description");
        isFormValid.value = false;
      }
      if (addInvoiceBodyParam.value.reference_no == "" && addInvoiceBodyParam.value.payment_type !== 1) {
        rules.value.reference_no.show = true;
        errorMessages.value.push("Please enter Reference No.");
        isFormValid.value = false;
      }
      if (addInvoiceBodyParam.value.expiry_date_time == "" && addInvoiceBodyParam.value.payment_type === 1) {
        rules.value.expiry_date.show = true;
        errorMessages.value.push("Expiry date is mandatory for online");
        isFormValid.value = false;
      }
      
    };

    const indexLead = [] as Array<number>;
    const indexPolicyId = [] as Array<number>;
    const indexIsAgencyRepair = [] as Array<number>;
    const indexOriginalPrice = [] as Array<number>;
    const indexSalePrice = [] as Array<number>;
    const indexIsDiscountGiven = [] as Array<number>;
    const indexDiscountAmount = [] as Array<number>;
    const validateInvoiceData = (invoice) => {
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (
        invoice.lead == "" ||
        invoice.policy_id == "" ||
        invoice.is_agency_repair == null ||
        invoice.original_price == "" ||
        invoice.is_discount_given == null ||
        invoice.discount_amount == null ||
        invoice.sale_price == ""
      ) {
        invoice.validation = true;
        isFormValid.value = false;
      } else {
        invoice.validation = false;
        isFormValid.value = true;
      }
      // if (!decimalPlaces.test(String(invoice.original_price))) {
      //   invoice.validation = true;
      //   isFormValid.value = false;
      // }
      // if (!decimalPlaces.test(String(invoice.discount_amount))) {
      //   invoice.validation = true;
      //   isFormValid.value = false;
      // }

      invoiceData.value.forEach((invoice, index) => {
        if (invoice.lead === "") {
          rules.value.lead.show = true;
          indexLead.push(index);
          isFormValid.value = false;
        }
        if (invoice.policy_id === "") {
          rules.value.insurance_plan.show = true;
          indexPolicyId.push(index);
          isFormValid.value = false;
        }
        console.log(invoice.is_agency_repair)
        if (invoice.is_agency_repair === "") {
          rules.value.is_agency_repair.show = true;
          indexIsAgencyRepair.push(index);
          isFormValid.value = false;
        }
        if(!decimalPlaces.test(String(invoice.original_price))) {
          rules.value.policy_price.show = true;
          rules.value.policy_price.message = 'Please enter upto 2 decimal places only';
          indexOriginalPrice.push(index);
          isFormValid.value = false;
        }
        if (invoice.original_price === 0) {
          rules.value.policy_price.show = true;
          indexOriginalPrice.push(index);
          isFormValid.value = false;
        }
        if (invoice.sale_price === 0) {
          rules.value.sale_prices.show = true;
          indexSalePrice.push(index);
          isFormValid.value = false;
        }
        if(invoice.original_price > 9999999999) {
          isFormValid.value = false;
          // notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
          rules.value.policy_price.show = true;
          rules.value.policy_price.message = 'Incorrect amount';
          indexOriginalPrice.push(index);
        }

        if(invoice.sale_price > 9999999999) {
          isFormValid.value = false;
          rules.value.sale_prices.show = true;
          rules.value.sale_prices.message = 'Incorrect amount';
          indexSalePrice.push(index);
          // notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
        }

        // if(invoice.original_price > 9999999999 || invoice.sale_price > 9999999999) {
        //   isFormValid.value = false;
        //   notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
        // }
        if(!decimalPlaces.test(String(invoice.sale_price))) {
          rules.value.sale_prices.show = true;
          rules.value.sale_prices.message = 'Please enter upto 2 decimal places only';
          indexSalePrice.push(index);
          isFormValid.value = false;
        }
        if (invoice.is_discount_given === "") {
          rules.value.discount_given.show = true;
          indexIsDiscountGiven.push(index);
          isFormValid.value = false;
        }
        // if (invoice.discount_amount === 0) {
        //   rules.value.discount_amount.show = true;
        //   indexDiscountAmount.push(index);
        //   isFormValid.value = false;
        // }

        if (
          invoice.discount_amount > 0 &&
          invoice.is_discount_given == "0"
        ) {
          rules.value.discount_given.message =
            "Select Yes as there is a discount";
          rules.value.discount_given.show = true;
          invoice.validation = true;
          indexIsDiscountGiven.push(index);
          isFormValid.value = false;
        }
      });

      return invoice;
    };

    // let agentList = ref({
    //   data: [],
    //   options: salesAgentList,
    //   placeholder: "Please Select Policy Sales Agent",
    //   searchable: true,
    //   createTag: true
    // });
    const onSalesAgentChange = (event) => {
      addInvoiceBodyParam.value.policy_sales_agent_id = event;
    };
    const resetSalesAgent = () => {
      addInvoiceBodyParam.value.policy_sales_agent_id = null;
    };

    let file: any;
    const handleFileUpload = (event) => {
      // formData = new FormData();
      rules.value.file_data.message = 'Please upload file for COD / Direct'
      isFormValid.value = true
      rules.value.file_data.show = false

      file = event.target.files[0];
      if (addInvoiceBodyParam.value.payment_type != "1") {
        rules.value.file_data.show = false;
        isFormValid.value = false;
      }

      
      if((event.target.files[0].size  / 1024 / 1024) > 2) {
        rules.value.file_data.show = true
        rules.value.file_data.message = 'Document size exceed limit of 2mb'
        isFormValid.value = false
      }
      
      //
    };
    const setPaymentMode = () => {
      // console.log("addInvoiceBodyParam", addInvoiceBodyParam);
      // if (addInvoiceBodyParam.value.payment_type == "3") {
      //   console.log(addInvoiceBodyParam.value.payment_type);
      //   addInvoiceBodyParam.value.order_description = "-1";
      // } else {
      //   addInvoiceBodyParam.value.order_description = "";
      // }
      isFormValid.value = false;
      rules.value.payment_type.show = false
      rules.value.file_data.show = false;
      rules.value.expiry_date.show = false;
      rules.value.file_data.message = 'Please upload file for COD / Direct'
    };

    const disabledDate = (time: Date) => {
      return Date.now() > time.getTime();
    };

    const onSelectCustomer = (customer) => {
      isShowValidate.value = false;
      selectedCustomer = customer;
      addCustomerInfo();
      onSelectCustomerRef.value.clear();
    };

    const resetSelectCustomer = () => {
      resetSearchResult();
      isShowValidate.value = false;
      selectedCustomer = [];
    };

    const addCustomerInfo = () => {
      rules.value.lead_data.show = false;
      if (Object.keys(selectedCustomer).length > 0) {
        const data = customerInfo.value.findIndex(
          (item) => item.id == selectedCustomer["id"]
        );
        if (data == -1) {
          customerInfo.value.push(selectedCustomer);
          selectedCustomer = [];
          resetSearchResult();
          hideModal(searchCustomerModalRef.value);
        } else {
          notif.simple(
            "Duplicate Customer",
            "warning",
            "Please select another customer",
            0
          );
        }
      } else {
        isShowValidate.value = true;
      }
    };

    const isLoaded = ref<boolean>(false);

    const errorMessage = ref<string>("");

    const addInvoiceInfo = (customer, isAddInvoiceData = true) => {
      isShowLeadValidate.value = false;
      rules.value.lead_data.show = false;
      let params = {
        customer_id: customer.id
      };
      Promise.all([ManualOrderService.getLeadsByCustomer(params)]).then(
        (data) => {
          const getLeads = store.getters.getLeads;

          const getLeadsCount = getLeads.length;
          let invoiceDataCount = 0;
          if (isAddInvoiceData) {
            invoiceDataCount = invoiceData.value.filter(function (invoice) {
              return invoice.customer_id == customer.id;
            }).length;
          }

          console.log(invoiceData.value)
          console.log('getLeadsCount',getLeadsCount,invoiceDataCount)

          if (getLeadsCount > 0 && getLeadsCount > invoiceDataCount) {
            // customer.leads = ref({
            //   value: null,
            //   options: getLeads,
            //   placeholder: "Please Select Lead",
            //   searchable: true,
            //   createTag: true
            // });
            customer.leads = getLeads;
            invoiceInfo.value.unshift(customer);

            if (isAddInvoiceData) {
              let input = {
                lead: "",
                lead_id: "",
                policy_id: "",
                customer_id: customer.id,
                is_agency_repair: "",
                is_agency_repair_disabled: false,
                original_price: 0,
                sale_price: 0,
                name: customer.name,
                email: customer.email,
                phone_number: customer.phone_number,
                parent_invoice_id: parentInvoiceId.value,
                validation: false,
                is_discount_given: "0",
                discount_amount: 0,
                vehicle_type:1
              };

              invoiceData.value.unshift(input);
            }
          } else {
            isShowLeadValidate.value = true;
            if (invoiceDataCount == 0) {
              errorMessage.value = "Leads do not exist";
            }

            if (invoiceDataCount > 0) {
              errorMessage.value = "No more leads exist for this Customer";
            }
          }

        }
      );
      console.log(invoiceData.value)
      console.log(invoiceInfo.value)

    };

    const onChangeSalePrice = () => {
      // totalAmount.value = 0;
      // invoiceData.value.forEach((invoice) => {
      //   const salePrice = parseFloat(invoice.sale_price);
      //   totalAmount.value += salePrice;
      // });
    };

    const totalAmount = computed(() => {
      return invoiceData.value.reduce((total, item) => {
        return (total += parseFloat(item.sale_price.toString()));
      }, 0).toFixed(2);
    });

    const totalPolicyPrice = computed(() => {
      return invoiceData.value.reduce((total, item) => {
        return (total += parseFloat(item.original_price.toString()));
      }, 0).toFixed(2);
    });

    const totalDiscountAmount = computed(() => {
      return invoiceData.value.reduce((total, item) => {
        return (total += parseFloat(item.discount_amount.toString()));
      }, 0).toFixed(2);
    });

    const addTempMO = (invoice, index) => {
      invoice = validateInvoiceData(invoice);
      if (!invoice.validation) {
        addTempMOParam.value.form_session_id = formSessionId.value;
        addTempMOParam.value.invoice_id =
          invoice.invoice_id !== undefined ? invoice.invoice_id : "";
        addTempMOParam.value.policy_sales_agent_id =
          addInvoiceBodyParam.value.policy_sales_agent_id;
        addTempMOParam.value.policy_id = invoice.policy_id;
        addTempMOParam.value.customer_id = invoice.customer_id;
        addTempMOParam.value.lead_id = invoice.lead_id && Number.isInteger(invoice.lead_id) ? invoice.lead_id : invoice.lead;
        addTempMOParam.value.is_agency_repair = invoice.is_agency_repair;
        addTempMOParam.value.original_price = invoice.original_price;
        addTempMOParam.value.sale_price = invoice.sale_price;
        addTempMOParam.value.name = invoice.name;
        addTempMOParam.value.email = invoice.email;
        addTempMOParam.value.phone_number = invoice.phone_number;
        if (index == 0) {
          addTempMOParam.value.parent_invoice_id = 0;
        } else {
          addTempMOParam.value.parent_invoice_id = invoice.parent_invoice_id
            ? invoice.parent_invoice_id
            : parentInvoiceId.value;
        }

        addTempMOParam.value.is_discount_given = invoice.is_discount_given;
        addTempMOParam.value.discount_amount = invoice.discount_amount;
        loading.value = true;
        Promise.all([
          ManualOrderService.addTempManualOrder(addTempMOParam.value)
        ])
          .then((data) => {
            loading.value = false;
            let getInvoice = computed(() => {
              return store.getters.getInvoice;
            });
            if (getInvoice.value.parent_invoice_id == 0) {
              parentInvoiceId.value = getInvoice.value.id;
            }
            invoiceData.value[index].invoice_id = getInvoice.value.id;
          })
          .catch(() => {
            loading.value = false;
            // console.log('error')
          });
      }
    };
    let leadDetails = ref<any>();
    const getDriverDetailsList = (customer_id, invoiceData) => {
      
      let lead_id = invoiceData.lead;

      if (
        route.params.invoiceId !== undefined &&
        route.params.invoiceId != null && 
        invoiceData.invoice_id !== undefined
      ) {
        lead_id = invoiceData.lead_id;
      }
      // alert(customer_id)
      // alert(lead_id)
      const quotesBodyParam = {
        customer_id: customer_id,
        lead_id: lead_id
      };
    console.log(quotesBodyParam)
      Promise.all([QuotesService.getDriverDetailsList(quotesBodyParam)]).then(
        (data) => {
          // console.log('quotes',data[0]);
          CustomerService.getCustomerDetails(store, customer_id);

          leadDetails.value = data[0];
          // console.log(
          //   "getLeadDetails",
          //   leadDetails.value[0].lead_driver_details[0].id
          // );
          fetchDriverDetails(leadDetails.value[0].lead_driver_details[0].id);
        }
      );
    };
    const fetchDriverDetails = (driver_details_id) => {
      QuotesService.fetchQuote({ driver_details_id: driver_details_id }).then(
        () => {
          isLoaded.value = true;
        }
      );
    };
    const resetload = () => {
      isLoaded.value = false;
    };

    const removeCustomerInfo = (customer) => {
      isShowLeadValidate.value = false;
      if (
        route.params.invoiceId !== undefined &&
        route.params.invoiceId != null
      ) {
        let parentInvoice = invoiceData.value.filter(function (item) {
          return item.customer_id == customer.id && item.parent_invoice_id == 0;
        }).length;

        const params = {
          customer_id: customer.id
        };

        if (parentInvoice > 0) {
          showModal(params);
          return;
        }
        deleteManualOrder(params);
      }
      customerInfo.value = customerInfo.value.filter(function (item) {
        return item.id != customer.id;
      });

      invoiceInfo.value = invoiceInfo.value.filter(function (item) {
        return item.id != customer.id;
      });
      invoiceData.value = invoiceData.value.filter(function (item) {
        return item.customer_id != customer.id;
      });
    };
    const removeInvoiceInfo = (invoice, index) => {
      if (
        route.params.invoiceId !== undefined &&
        route.params.invoiceId != null &&
        invoice.invoice_id != "" &&
        invoice.invoice_id !== undefined
      ) {
        const params = {
          invoice_id: invoice.invoice_id
        };
        if (invoice.parent_invoice_id == 0) {
          showModal(params);
          return;
        }

        deleteManualOrder(params);
      }

      invoiceInfo.value = invoiceInfo.value.filter(function (item, i) {
        return i != index;
      });
      invoiceData.value = invoiceData.value.filter(function (item, i) {
        return i != index;
      });
    };

    const deleteManualOrder = (params) => {
      Promise.all([ManualOrderService.deleteManualOrder(params)]).then(
        (data) => {
          // console.log('data',data)
        }
      );
    };

    const openModal = computed(() => {
      return store.getters.getModal;
    });

    const closeModal = (e) => {
      store.commit(Mutations.SET_MODAL, false);
    };

    const showModal = (params) => {
      store.commit(Mutations.SET_MODAL, true);
      store.commit(Mutations.SET_MODAL_VALUE, params);
    };

    const proceedModal = (e) => {
      store.commit(Mutations.SET_MODAL_LOADING);
      Promise.all([ManualOrderService.deleteManualOrder(e)]).then((data) => {
        store.commit(Mutations.SET_MODAL_LOADING);
        store.commit(Mutations.SET_MODAL, false);
        openSuccessMessage("Manual order deleted successfully");
        router.push({ name: "manual-order-list" });
      });
    };

    let getPolicyLists = computed(() => {
      return store.getters.getPolicyLists;
    });

    // let companyList = ref({
    //   placeholder: "Please Select",
    //   value: null,
    //   options: getPolicyLists,
    //   searchable: true,
    //   createTag: true
    // });

    // let agencyRepairList = ref({
    //   placeholder: "Please Select",
    //   data: [],
    //   value: null,
    //   options: [
    //     { value: "1", label: "Yes" },
    //     { value: "0", label: "No" }
    //   ],
    //   searchable: true,
    //   createTag: true
    // });
    let agencyRepairList = ref([
      { value: 1, label: "Yes" },
      { value: 0, label: "No" }
    ]);
    const onChangePolicyPrice = (e, index) => {
      // console.log({
      //   e,
      //   index
      // })
      const val = parseFloat(e.target.value.toString().replace(',', ''))
      rules.value.policy_price.show = false
      if(isNaN(val)) {
        invoiceData.value[index].sale_price = 0
      } else {
        invoiceData.value[index].sale_price = roundUp(val)
      }

        // roundUp(invoiceData.value[index].original_price);

        indexOriginalPrice.splice(index, 1);

      calculateDiscountAmount(index);
    };

    const calculateDiscountAmount = (index) => {
      const val = parseFloat(invoiceData.value[index].sale_price.toString().replace(',', ''))
      indexSalePrice.splice(index, 1);
      if (
        invoiceData.value[index].sale_price != 0 &&
        invoiceData.value[index].original_price != 0
      ) {
        if (
          val >
          invoiceData.value[index].original_price
        ) {
          invoiceData.value[index].is_discount_given = "0";
          invoiceData.value[index].discount_amount = 0;
        } else {
          let discountAmount = invoiceData.value[index].original_price -
              roundUp(val)
          if(roundUp(discountAmount) > 0 )
            invoiceData.value[index].is_discount_given = "1";
          else 
            invoiceData.value[index].is_discount_given = "0";
          invoiceData.value[index].discount_amount = roundUp(discountAmount);
        }
      }
    };

    const fixFigure = (e, index) => {
      invoiceData.value[index].sale_price = roundUp(parseFloat(e.target.value.toString().replace(',', '')))
      if(isNaN(invoiceData.value[index].sale_price)) {
        invoiceData.value[index].sale_price = 0
      }
    }

    const changeDiscountGiven = (event, index) => {
      invoiceData.value[index].is_discount_given = event.target.value;
      // console.log("invoiceData", invoiceData);
    };

    const openInvoiceModal = computed(() => {
      return store.getters.getInvoiceModal;
    });

    const closeInvoiceModal = (e) => {
      store.commit(Mutations.SET_INVOICE_MODAL);
    };

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    });

    const resetValidationMessage = () => {
      // alert('click')
      rules.value.customer_data.show = false;
      setTimeout(() => {
        const onSelectCustomerRef:any = inst?.refs?.onSelectCustomerRef
        onSelectCustomerRef.focusSearch()
        
      }, 1000);
    };
    // let searchCustomer=ref('');

    const addDayDate = computed(() => {
      const today = new Date();
      const tomorrow = new Date(today);
      return moment(tomorrow.setDate(tomorrow.getDate() + 1)).format(
        "YYYY-MM-DD"
      );
    });

    const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

    const focusField = () => {
      const { policy_agent, lead, insurance_plan, is_agency_repair, policy_price, sale_prices, payment_type, order_description, reference_no, expiry_date, file_data } = rules.value
      if(policy_agent.show) {
      const ref_agent_id:any = inst?.refs.ref_agent_id
      ref_agent_id.focus()
      }

      if(!policy_agent.show && invoiceData.value.length > 0) {
        invoiceData.value.forEach((invoice, index) => {
          if(lead.show && !invoice.lead) {
            const ref_lead:any = inst?.refs.ref_lead
            ref_lead.forEach(lead => {
              lead.focus()
            })
          }
          if(!lead.show && insurance_plan.show && !invoice.policy_id) {
            const ref_insurance_plan:any = inst?.refs.ref_insurance_plan
            ref_insurance_plan.forEach(insurance_plan => {
              insurance_plan.focus()
            })
          }
          if(!lead.show && !insurance_plan.show && is_agency_repair.show && !invoice.is_agency_repair) {
            const ref_is_agency_repair:any = inst?.refs.ref_is_agency_repair
            ref_is_agency_repair.forEach(is_agency_repair => {
              is_agency_repair.focus()
            })
          }
          if(!lead.show && !insurance_plan.show && !is_agency_repair.show && policy_price.show && invoice.original_price <= 0) {
            const ref_original_price:any = inst?.refs.ref_original_price
            ref_original_price.forEach(original_price => {
              original_price.isFocus()
            })
          }
          if(!lead.show && !insurance_plan.show && !is_agency_repair.show && !policy_price.show && sale_prices.show && invoice.sale_price <= 0) {
            const ref_sale_price:any = inst?.refs.ref_sale_price
            ref_sale_price.forEach(sale_price => {
              sale_price.isFocus()
            })
          }
        })
      }

      const ref_file_receipt:any = inst?.refs.ref_file_receipt

      if(!policy_agent.show && invoiceData.value.length > 0 && !lead.show && !insurance_plan.show && !is_agency_repair.show && !policy_price.show && !sale_prices.show) {
        if(payment_type.show) {
          const ref_payment_type:any = inst?.refs.ref_payment_type
          ref_payment_type.focus()
        }
        if(!payment_type.show && order_description.show) {
          const ref_order_description:any = inst?.refs.ref_order_description
          ref_order_description.focus()
        }

        
        if(!payment_type.show && !order_description.show) {
          // payment type not Online
          if(inst?.refs.ref_reference_no) {
            if(reference_no.show) {
              const ref_reference_no:any = inst?.refs.ref_reference_no
                  ref_reference_no.focus()
            }
            if(inst?.refs?.ref_file_receipt) {
              if(!reference_no.show && ref_file_receipt.files.length === 0) {
                ref_file_receipt.focus()
                ref_file_receipt.click()
              }
            }
          }
          // payment type Online
          if(inst?.refs.ref_expiry_date) {
            if(expiry_date.show) {
              const ref_expiry_date:any = inst?.refs.ref_expiry_date
              ref_expiry_date.isFocus()
            }
          }
        }
      }
    }

    const toLanguage = () => {
        const languageRef:any = inst?.refs.languageRef
        languageRef.focus()
    }

    const toOrderDescription = () => {
        const ref_order_description:any = inst?.refs.ref_order_description
        ref_order_description.focus()
    }

    const toExpiryDate = () => {
      const ref_expiry_date:any = inst?.refs.ref_expiry_date
      ref_expiry_date.isFocus()
    }

    const toCancel = () => {
      const saveRef:any = inst?.refs.saveRef
        saveRef.focus()
    }

    const changePlan = (index) => {
      rules.value.insurance_plan.show = false
      rules.value.is_agency_repair.show = false;

      const policyId = invoiceData.value[index].policy_id

      const data = getPolicyLists.value.find(
          (item) =>
            item.id == policyId
      );
      console.log(data);

      if(data.policy_type == 5){
        invoiceData.value[index].is_agency_repair = 0;
        invoiceData.value[index].is_agency_repair_disabled = true;
      }
      else
      {
        invoiceData.value[index].is_agency_repair_disabled = false;

      }
    }

    const changeLead = (index) => {
      rules.value.lead.show = false
      const leadId = invoiceData.value[index].lead;
      console.log(leadId);

      const lead = invoiceInfo.value[index].leads.find(
          (item) =>
            item.value == leadId
      );

      invoiceData.value[index].vehicle_type = lead.vehicle_type;
    }

    return {
      paymentDate,
      fixFigure,
      toExpiryDate,
      toCancel,
      toLanguage,
      toOrderDescription,
      isFormValid,
      roundUp,
      addDayDate,
      validateManualOrderForm,
      indexLead,
      indexPolicyId,
      indexIsAgencyRepair,
      indexOriginalPrice,
      indexSalePrice,
      indexIsDiscountGiven,
      indexDiscountAmount,
      onSelectCustomerRef,
      resetSearchResult,
      resetValidationMessage,
      openInvoiceModal,
      closeInvoiceModal,
      isError,
      loading,
      changeDiscountGiven,
      calculateDiscountAmount,
      onChangePolicyPrice,
      agencyRepairList,
      // companyList,
      getPolicyLists,
      totalPolicyPrice,
      totalDiscountAmount,
      errorMessage,
      resetload,
      isLoaded,
      isClick,
      errorMessages,
      addInvoiceBodyParam,
      addManualOrder,
      handleFileUpload,
      expiryDate,
      getExpiryDateTime,
      resetSalesAgent,
      errMsg,
      // agentList,
      salesAgentList,
      onSalesAgentChange,
      setPaymentMode,
      rules,
      customerEmail,
      disabledDate,
      getSearchResult,
      searchList,
      // searchCustomer,
      // getSearch,
      searchResult,
      showLoader,
      onSelectCustomer,
      keyword,
      customerInfo,
      addCustomerInfo,
      selectedCustomer,
      addInvoiceInfo,
      invoiceInfo,
      onChangeSalePrice,
      totalAmount,
      addTempMO,
      invoiceData,
      getDriverDetailsList,
      resetSelectCustomer,
      fetchDriverDetails,
      isShowValidate,
      searchCustomerModalRef,
      isShowLeadValidate,
      removeCustomerInfo,
      removeInvoiceInfo,
      openModal,
      closeModal,
      proceedModal,
      isView,
      editInvoiceObj,
      route,
      changePlan,
      changeLead
    };
  }
});
